import React, { useState, useEffect, useCallback } from 'react';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import { navigate } from 'gatsby';
import { useForm, Controller } from 'react-hook-form';
import QueryString from 'query-string';
import styled from 'styled-components';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  InputText,
  InputPassword,
  Button,
  Notification,
  Text,
  Modal
} from '@dls/web';
import {
  LoginHeading,
  BodyText,
  ModalText,
  ButtonWrapper,
  FieldWrapper,
  StyledLink
} from '../../components/styles';
import {
  isValidEmail,
  formatUsername,
  formatUsernameforBackend,
  handleUsernameValidation,
  validateUsername,
  validateUsernameforGE,
  validateUsernamePortal18,
  pushAnalyticsEvent,
  convertToBoolean
} from '../../helpers/utils';
import SETTINGS from '../../constants/appSettings';
import InputIdentifier from '../../components/InputIdentifier';
import Api from '../../helpers/Api';
import { LoginRequest } from '../../helpers/RequestResponse';
import { otpTriggerState, resetOtpTriggerState } from '../OTPView/utils';
import { MFANavigationState } from '../../helpers/types';
import { loginBannerErrorState, userConfigState } from './utils';
import { generateJWE } from '../../helpers/encryption';
import { flowConfigState } from '../..';
import { COPY, CODE, ERROR, GENERAL } from '../../constants/appCopy.json';

interface LoginData {
  username: string;
  password: string;
}

const LearnMoreLink = styled(StyledLink)`
  margin-top: 24px;
`;

let isEmailVerify = false;
let isSingnetUser = false;
let isPostLogin = false;
let skip = false;

export default function OnePassLogin({ isGE }) {
  const qs = QueryString.parse(location.search);
  const setOtpTrigger = useSetRecoilState(otpTriggerState);
  const setResetOTP = useSetRecoilState(resetOtpTriggerState);
  const [bannerError, setBannerError] = useRecoilState(loginBannerErrorState);
  const [userConfig, setUserConfig] = useRecoilState(userConfigState);
  const [loading, setLoading] = useState<any>(false);
  const [modal, setModal] = useState<any>(false);
  const [notifyModal, setNotifyModal] = useState<any>(false);
  const [singnetModal, setSingnetModal] = useState<any>(false);
  const [reloadModal, setReloadModal] = useState<boolean>(false);
  const [resetId, setResetId] = useState('');
  const [error, setError] = useState('');
  const [
    resetPasswordCaptchaBanner,
    setResetPasswordCaptchaBanner
  ] = useState<string>('');
  const flowConfig = useRecoilValue(flowConfigState);
  const portal = flowConfig?.portal.toLowerCase();
  const isMyportal = ['myportal'].includes(portal);
  const isUDF = flowConfig?.clientId.includes('UDF');
  const captchaRef = React.useRef();
  const blockSignet = ['myportal', 'udf'].includes(portal);
  const [captchaRetryCount, setCaptchaRetryCount] = useState(0);

  const [showTraditionalCaptcha, setShowTraditionalCaptcha] = useState(() => {
    return convertToBoolean(SETTINGS.SHOW_FALLBACK_CAPTCHA)
  });

  const { control, handleSubmit, errors, reset } = useForm<LoginData>({
    mode: 'onTouched'
  });

  const handleNavigation = () => {
    let url = new URL(window.location.href);
    url.searchParams.delete('error');
    url.searchParams.delete('ssoerror');
    url.searchParams.delete('reset');
    url.searchParams.delete('verify');
    return url.search;
  };

  const resetError = () => {
    setBannerError(ERROR.SOMETHING_WRONG);
    setLoading(false);
  };

  const handleForgotPwd = () => {
    setModal(true);
  };

  const blockSingnetIDs = (
    <>
      SingNet email accounts are temporarily not supported. Use an alternative
      email or mobile instead.{' '}
      <Text
        type="link"
        inline
        onMouseDown={() =>
          window
            .open(
              'https://www.singtel.com/personal/support/singnet#accountupdate',
              '_blank'
            )
            .focus()
        }
      >
        {' '}
        Learn more
      </Text>
    </>
  );
  const blockSingnetMsg = (
    <>
      We’ve recently updated our security policies. For enhanced security,
      <Text type="link" inline onMouseDown={handleForgotPwd}>
        {' '}
        reset your password
      </Text>{' '}
      to continue.
      <Text
        type="link"
        inline
        onMouseDown={() =>
          window
            .open(
              'https://www.singtel.com/personal/support/singnet#accountupdate',
              '_blank'
            )
            .focus()
        }
      >
        {' '}
        Learn more
      </Text>
    </>
  );

  const AccountLockMsg = (
    <>
      Your account has been locked for security reasons. To proceed,{' '}
      <Text type="link" inline onMouseDown={handleForgotPwd}>
        reset your password.
      </Text>
    </>
  );

  const AlreadyExistsMsg = (
    <>
      Looks like you already have a OnePass account. Try logging in or{' '}
      <Text type="link" inline onMouseDown={handleForgotPwd}>
        {' '}
        reset your password
      </Text>{' '}
      instead.
    </>
  );

  const handleOnchange = (data) => {
    setError(blockSingnetMsg);
    let isError = '';
    const formattedValue = formatUsername(data);
    if (isUDF) {
      isError = validateUsernameforGE(data);
      setResetId(data);
    } else if (isMyportal) {
      isError = validateUsernamePortal18(data);
      setResetId(data);
    } else {
      isError = validateUsername(formattedValue);
      setResetId(formattedValue);
    }
    setError(isError === true ? '' : isError);
  };

  const handleCaptchaError = () => {
    setCaptchaRetryCount((reset) => reset + 1);
    setBannerError(GENERAL.captcha.error.captchaError);
  };

  const handleOtpForReset = async (id, modal) => {
    if (error || !id) {
      setLoading(false);
      return;
    }
    const token = showTraditionalCaptcha
      ? captchaRef?.current?.getValue()
      : await captchaRef.current.executeAsync();

    if (!token) {
      setCaptchaRetryCount((reset) => reset + 1);
      setResetPasswordCaptchaBanner(GENERAL.captcha.error.captchaError);
      return;
    }
    setBannerError('');
    setLoading('inProgress');
    try {
      const payload = {
        flowId: flowConfig?.flowId,
        userId: id,
        reCaptchaToken: token,
        fallback: showTraditionalCaptcha
      };
      const response = await Api.sendOtpForReset(payload);
      if (response?.body) {
        setResetOTP({ ...response?.body, resetId: id, notify: !modal });
        const state: MFANavigationState = { initialRoute: 'resetPasswordOtp' };
        navigate(`/mfa${handleNavigation()}`, { state });
      } else {
        if (
          response?.metadata?.status?.toLowerCase() ===
            'missing captcha token' ||
          response?.error?.data?.message?.toLowerCase() ===
            'invalid interaction'
        ) {
          setBannerError(
            "Try again later. Your device or network may be sending automated queries. To protect our users, we can't process your request right now."
          );
          if (modal) {
            setModal(false);
          }
        } else if (
          response?.error?.data?.code === CODE.USER_ACCOUNT_TEMP_LOCKED
        ) {
          setBannerError(ERROR.USER_ACCOUNT_TEMP_LOCKED);
          if (modal) {
            setModal(false);
          }
        } else if (
          response?.error?.data?.code === 'CONTACT_NOT_FOUND_FOR_OTP'
        ) {
          setBannerError(ERROR.FURTHER_ASSISTANCE);
          if (modal) {
            setModal(false);
          }
        } else if (
          response?.error?.data?.code === 'TEMPORARY_BLOCKED_SINGNET_ACCOUNT'
        ) {
          setBannerError('BLOCK_SINGNET_IDS');
          if (modal) {
            setModal(false);
          }
        } else {
          if (modal) {
            setResetOTP({
              contact: id,
              mode: isValidEmail(id) ? 'EMAIL' : 'SMS'
            });
            const state: MFANavigationState = {
              initialRoute: 'resetPasswordOtp'
            };
            navigate(`/mfa${handleNavigation()}`, { state });
            // setError(isUDF ? 'Enter a valid ID': 'Enter a valid OnePass ID');
          } else {
            setBannerError(ERROR.SOMETHING_WRONG);
            setModal(false);
          }
        }
        setLoading(false);
      }
    } catch (e) {
      if (modal) {
        setError(ERROR.SOMETHING_WRONG);
      } else {
        setBannerError(ERROR.SOMETHING_WRONG);
      }
      setLoading(false);
    }
  };

  const handleReset = () => {
    const userId = formatUsernameforBackend(resetId, portal);
    handleOtpForReset(userId, true);
  };

  async function handleLogin(data: LoginData) {
    const flowId = sessionStorage.getItem('flowId');
    // If flowId is null show modal to reload page
    if (!flowId) {
      setReloadModal(true);
      return;
    }
    const token = showTraditionalCaptcha
      ? captchaRef?.current?.getValue()
      : await captchaRef.current.executeAsync();
    if (!token) {
      handleCaptchaError();
      return;
    }
    setLoading('inProgress');
    const targetUrl = sessionStorage.getItem('targetUrl');
    const userId = formatUsernameforBackend(data.username, portal);
    const isSingnetEmail = userId.includes('@singnet.com');
    if (isSingnetEmail || isMyportal) {
      isSingnetUser = true;
      setUserConfig({
        ...userConfig,
        isSingnetUser
      });
    }
    sessionStorage.setItem('userId', userId);

    try {
      // Step 1: get public key
      const keyResponse = await Api.getPublicKey();
      const encryptPayload = JSON.stringify({
        agentId: flowConfig?.agentId,
        clientId: flowConfig?.clientId,
        channelId: flowConfig?.channelId,
        userId: userId.toLowerCase(),
        password: data.password,
        flowId: flowId,
        grantType: flowConfig?.grantType,
        referenceId: keyResponse?.body?.keys[0].kid,
        jti: keyResponse?.body?.keys[0].kid,
        iat: Math.floor(Date.now() / 1000),
        exp: Math.floor(new Date(Date.now() + 7200000).valueOf() / 1000)
      });

      // Step 2: encrypt data
      const header = { enc: 'A128GCM', alg: 'RSA-OAEP-256' };
      const jwe = await generateJWE(
        encryptPayload,
        keyResponse?.body?.keys[0],
        header
      );

      // Step 3: do login
      let loginPayload: LoginRequest = {
        referenceId: keyResponse?.body?.keys[0].kid,
        flowId,
        data: jwe,
        grantType: flowConfig?.grantType,
        reCaptchaToken: token,
        fallback: showTraditionalCaptcha
      };
      if (targetUrl) {
        loginPayload = { ...loginPayload, targetUrl };
      }
      pushAnalyticsEvent(
        'uiam_login',
        'login_start',
        `${flowConfig?.portal} - Login with MFA started`
      );

      try {
        const loginResponse = await Api.login(loginPayload);
        if (loginResponse?.body) {
          setBannerError('');
          Api._setToken(loginResponse?.body?.accessToken); // 1FA
          if (loginResponse?.body?.code === 'RESET_USER_PASSWORD') {
            handleOtpForReset(userId, false);
            return;
          } else if (loginResponse?.body.code === 'EMAIL_NOT_VERIFIED') {
            isEmailVerify = true;
            isPostLogin = true;
            setUserConfig({
              ...userConfig,
              isPostLogin,
              isEmailVerify,
              isSingnetUser,
              userFlow: 'verifyEmail',
              contact: loginResponse?.body?.contact
            });
          } else if (loginResponse?.body.code === 'NO_PREFERRED_EMAIL') {
            isPostLogin = true;
            setUserConfig({
              ...userConfig,
              userFlow: 'addEmail',
              isPostLogin,
              isSingnetUser
            });
          } else if (loginResponse?.body.code === 'NO_PREFERRED_MOBILE') {
            isPostLogin = isUDF ? false : true;
            setUserConfig({
              ...userConfig,
              userFlow: isUDF ? 'addMobileComplete' : 'addMobile',
              isPostLogin,
              isSingnetUser
            });
          } else if (
            loginResponse?.body.code ===
            'EMAIL_NOT_VERIFIED_AND_NO_PREFERRED_MOBILE'
          ) {
            isEmailVerify = true;
            isPostLogin = true;
            setUserConfig({
              ...userConfig,
              userFlow: 'addMobile',
              isPostLogin,
              isEmailVerify,
              isSingnetUser
            });
          } else if (
            loginResponse?.body.code === 'NO_PREFERRED_EMAIL_AND_MOBILE'
          ) {
            if (isSingnetUser) {
              const state: MFANavigationState = {
                initialRoute: 'addMobile',
                skip: true
              };
              navigate(`/mfa${handleNavigation()}`, { state });
              return;
            } else {
              isPostLogin = true;
              setUserConfig({
                ...userConfig,
                userFlow: 'addMobile',
                isPostLogin,
                isSingnetUser
              });
            }
          } else if (
            loginResponse?.body.code === 'NO_PREFERRED_MOBILE_SINGNET'
          ) {
            if (isSingnetUser && isMyportal) {
              const state: MFANavigationState = {
                initialRoute: 'addMobile',
                skip: true
              };
              navigate(`/mfa${handleNavigation()}`, { state });
              return;
            } else {
              isPostLogin = isUDF ? false : true;
              setUserConfig({
                ...userConfig,
                userFlow: 'addMobile',
                isPostLogin,
                isSingnetUser
              });
            }
          } else if (
            loginResponse?.body.code === 'NO_PREFERRED_EMAIL_SINGNET'
          ) {
            skip = true;
          }

          pushAnalyticsEvent(
            'uiam_login',
            'login_success',
            `${flowConfig?.portal} - Login with MFA Authentication Success`
          );

          setCaptchaRetryCount(0);
          // Step 4: send otp
          try {
            const otpResponse = isUDF
              ? await Api.sendOtp({ mode: 'EMAIL' })
              : await Api.sendOtp({});
            if (otpResponse?.body) {
              setOtpTrigger(otpResponse?.body);
              pushAnalyticsEvent(
                'uiam_otp',
                `otp_${isGE ? 'email' : 'sms'}_sent_success`,
                `${isGE ? 'email' : 'sms'} OTP Sent Success`
              );
              // mobile flow
              const state: MFANavigationState = {
                initialRoute:
                  otpResponse?.body?.mode === 'EMAIL' ? 'emailOtp' : 'smsOtp',
                skip
              };
              navigate(`/mfa${handleNavigation()}`, { state });
            } else if (
              otpResponse.error.data.code === CODE.CHALLENGE_CHECK_REQUIRED
            ) {
              // challenge flow
              setUserConfig({
                ...userConfig,
                isEmailVerify,
                isSingnetUser,
                userFlow: 'verifyNric',
                isPostLogin
              });
              const state: MFANavigationState = {
                initialRoute: 'challengeNric'
              };
              navigate(`/mfa${handleNavigation()}`, { state });
            } else if (otpResponse.error.data.code === CODE.ADD_MOBILE_FLOW) {
              // add mobile flow
              setUserConfig({
                ...userConfig,
                isEmailVerify,
                isSingnetUser,
                userFlow: 'addMobile',
                isPostLogin
              });
              const state: MFANavigationState = { initialRoute: 'emailOtp' };
              navigate(`/mfa${handleNavigation()}`, { state });
            } else {
              pushAnalyticsEvent(
                'uiam_otp',
                `otp_${isGE ? 'email' : 'sms'}_sent_failure`,
                `${isGE ? 'email' : 'sms'} OTP Sent Failed`
              );
              reset();
              resetError();
              return;
            }
          } catch (e) {
            pushAnalyticsEvent(
              'uiam_otp',
              `otp_${isGE ? 'email' : 'sms'}_sent_failure`,
              `${isGE ? 'email' : 'sms'} OTP Sent Failed`
            );
            resetError();
          }
        } else {
          if (
            loginResponse?.metadata?.status?.toLowerCase() ===
              'missing captcha token' ||
            loginResponse?.error?.data?.message?.toLowerCase() ===
              'invalid interaction'
          ) {
            setBannerError(
              "Try again later. Your device or network may be sending automated queries. To protect our users, we can't process your request right now."
            );
            setShowTraditionalCaptcha(true);
          }
          if (
            loginResponse.error.data.code === CODE.USER_AUTHENTICATION_FAILED ||
            loginResponse.error.data.code === CODE.INVALID_USER_PROFILE
          ) {
            setBannerError(ERROR.USER_AUTHENTICATION_FAILED);
            pushAnalyticsEvent(
              'uiam_login',
              'login_failure',
              `${flowConfig?.portal} - Login with MFA Failed`
            );
          }
          if (loginResponse.error.data.code === CODE.USER_ACCOUNT_LOCKED) {
            pushAnalyticsEvent(
              'uiam_login',
              'account_locked',
              `${flowConfig?.portal} - Login with MFA Account locked`
            );
            setBannerError(ERROR.USER_ACCOUNT_LOCKED);
          }
          if (
            loginResponse.error.data.code ===
            CODE.USER_ACCOUNT_DELINKED_AND_LOCKED
          ) {
            pushAnalyticsEvent(
              'uiam_login',
              'account_locked',
              `${flowConfig?.portal} - Login with MFA Account locked`
            );
            setNotifyModal(true);
            setBannerError(ERROR.USER_ACCOUNT_DELINKED_AND_LOCKED);
          }
          if (
            loginResponse.error.data.code ===
            'TEMPORARY_BLOCKED_SINGNET_ACCOUNT'
          ) {
            setBannerError('BLOCK_SINGNET_IDS');
            setSingnetModal(true);
            setLoading(false);
            return;
          }
          if (loginResponse.error.data.code === CODE.USER_ACCOUNT_TEMP_LOCKED) {
            setBannerError(ERROR.USER_ACCOUNT_TEMP_LOCKED);
            pushAnalyticsEvent(
              'uiam_login',
              'account_temp_locked',
              `${flowConfig?.portal} - Login with MFA Account Temporarily locked`
            );
          }
          if (loginResponse.error.data.code === 'TARGET_URL_NOT_VALID') {
            window.location.href = loginResponse.error.data.callbackUrl;
            return;
          }
          reset();
          setLoading(false);
          return;
        }
      } catch (e) {
        resetError();
      }
    } catch (e) {
      resetError();
    }
  }
  useEffect(() => {
    const flowId = qs.flowId;
    const targetUrl = qs.targetUrl;
    if (flowId) sessionStorage.setItem('flowId', flowId);
    if (targetUrl) sessionStorage.setItem('targetUrl', targetUrl);

    if (qs.ssoerror || qs.error) {
      setBannerError(ERROR.SOMETHING_WRONG);
    }
    if ('reset' in qs) {
      handleForgotPwd();
    }

    if ('verify' in qs) {
      setBannerError(
        'We’re pending some details to complete your account verification. Log in again to proceed.'
      );
    }
  }, []);

  const bannerErrorContent = (code) => {
    switch (code) {
      case 'BLOCK_SINGNET_IDS':
        return blockSingnetIDs;
      case 'BLOCK_SINGNET':
        return blockSingnetMsg;
      case 'ACCOUNT_LOCKED':
        return AccountLockMsg;
      case 'CUST_ID_ALREADY_EXISTS':
        return AlreadyExistsMsg;
      default:
        return code;
    }
  };

  const onRelaodPage = useCallback(() => {
    window.location.reload();
    setReloadModal(false);
  }, []);

  if (captchaRetryCount >= Number(SETTINGS.MAX_CAPTCHA_ATTEMPTS)) {
    navigate('/maxattempts');
  }

  return (
    <>
      <LoginHeading>
        {' '}
        {COPY.login[portal]?.title || COPY.login.title}{' '}
      </LoginHeading>
      <BodyText type="body">
        {COPY.login[portal]?.body || COPY.login.body}
      </BodyText>
      {bannerError ? (
        <div data-testid="notification" style={{ marginBottom: '24px' }}>
          <Notification
            type="alert"
            content={bannerErrorContent(bannerError)}
          />
        </div>
      ) : null}
      <form onSubmit={handleSubmit(handleLogin)}>
        <FieldWrapper>
          <Controller
            name="username"
            rules={{ validate: handleUsernameValidation(portal) }}
            defaultValue=""
            control={control}
            render={({ value, onChange, onBlur, ref }) => (
              <InputIdentifier
                format={flowConfig?.format}
                name="username"
                label={COPY.login[portal]?.field_user || COPY.login.field_user}
                bgColor="haze"
                hintMessage={errors.username && errors.username.message}
                error={!!(errors.username && errors.username.message)}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                ref={ref}
              />
            )}
          />
        </FieldWrapper>
        {flowConfig?.grantType === 'password_grant' && (
          <>
            <FieldWrapper>
              <Controller
                name="password"
                rules={{ required: COPY.login.field_pass.requiredtext }}
                defaultValue=""
                control={control}
                render={({ value, onChange, onBlur }) => (
                  <InputPassword
                    value={value.toString()}
                    onChange={onChange}
                    onBlur={onBlur}
                    name="password"
                    label={COPY.login.field_pass.label}
                    bgColor="haze"
                    hintMessage={errors.password && errors.password.message}
                    error={errors.password && errors.password.message}
                  />
                )}
              />
            </FieldWrapper>
          </>
        )}
        <div>
          {showTraditionalCaptcha && (
            <ReCAPTCHA
              ref={captchaRef}
              size="normal"
              sitekey={SETTINGS.FALLBACK_CAPTCHA_SITE_KEY}
              style={{ margin: '20px 0px' }}
            />
          )}

          <ButtonWrapper>
            <Button fullWidth type="submit" loading={loading}>
              {COPY.login.cta_login}
            </Button>
          </ButtonWrapper>
          {!blockSignet && (
            <ButtonWrapper>
              <Button
                fullWidth
                secondary
                onClick={() => navigate(`/registration${handleNavigation()}`)}
                // onClick={() => window.top.location = `${SETTINGS.GC_CREATE_ACCOUNT}=${flowConfig?.portalType}`}
              >
                {COPY.login.cta_createaccount}
              </Button>
            </ButtonWrapper>
          )}
          {!isUDF ? (
            <Text
              type="link"
              onMouseDown={handleForgotPwd}
              style={{
                marginTop: '20px',
                display: 'block',
                textAlign: 'center'
              }}
            >
              {COPY.login.cta_forgotpassword}
            </Text>
          ) : (
            <Text
              type="link"
              // href={SETTINGS.UDF_FORGOT_PWD}
              onMouseDown={handleForgotPwd}
              style={{
                marginTop: '20px',
                display: 'block',
                textAlign: 'center'
              }}
            >
              Forgot password?
            </Text>
          )}
        </div>
        {/* {(!flowConfig?.clientId.includes('UDF') && (portal && portal.toLowerCase() !== 'myportal')) && (
        <div style={{ textAlign: 'center' }}>
          <LearnMoreLink
            href={isGE ? SETTINGS.GE_LERAN_MORE : SETTINGS.GC_LEARN_MORE}
            target="_blank"
          >
            {isGE ? COPY.login.cta_learnmore_ge : COPY.login.cta_learnmore_gc}
          </LearnMoreLink>
        </div>
        )} */}
      </form>
      <Modal
        visible={modal}
        onClose={() => {
          setModal(false);
          setResetId('');
          setError('');
        }}
        title={
          isUDF
            ? 'reset' in qs
              ? 'Change password'
              : 'Forgot password?'
            : COPY.login[portal]?.reset || COPY.login.reset
        }
      >
        <Modal.Content style={{ width: '460px' }}>
          <ModalText type="body">
            {isUDF
              ? 'Enter your email below and we will send you an OTP to reset your password.'
              : 'Verify your ID so we can help you get things right.'}
          </ModalText>
          {resetPasswordCaptchaBanner ? (
            <div data-testid="notification" style={{ marginBottom: '24px' }}>
              <Notification
                type="alert"
                content={bannerErrorContent(resetPasswordCaptchaBanner)}
              />
            </div>
          ) : null}
          <FieldWrapper>
            <InputText
              autoFocus
              bgColor="haze"
              label={COPY.login[portal]?.field_user || COPY.login.field_user}
              value={resetId}
              onChange={(e: any) => handleOnchange(e.target.value)}
              error={!!error}
              hintMessage={error}
            />
          </FieldWrapper>
          <div>
            {showTraditionalCaptcha && (
              <ReCAPTCHA
                ref={captchaRef}
                size="normal"
                sitekey={SETTINGS.FALLBACK_CAPTCHA_SITE_KEY}
                style={{ margin: '20px 0px' }}
              />
            )}
            <ButtonWrapper>
              <Button fullWidth onClick={handleReset} loading={loading}>
                {isUDF ? 'Reset password' : COPY.addmobile.cta_getotp}
              </Button>
            </ButtonWrapper>
          </div>
        </Modal.Content>
      </Modal>
      <Modal
        visible={notifyModal}
        onClose={() => {
          setNotifyModal(false);
        }}
        title={'Update your account'}
      >
        <Modal.Content style={{ width: '460px' }}>
          <ModalText type="body">
            We’ve recently updated our security policies. For enhanced security,
            reset your password to continue.{' '}
            <Text
              type="link"
              inline
              onMouseDown={() =>
                window
                  .open(
                    'https://www.singtel.com/personal/support/singnet#accountupdate',
                    '_blank'
                  )
                  .focus()
              }
            >
              Learn more
            </Text>
          </ModalText>
          <div>
            <ButtonWrapper>
              <Button fullWidth onClick={() => setModal(true)}>
                Reset password
              </Button>
            </ButtonWrapper>
          </div>
        </Modal.Content>
      </Modal>
      <Modal
        visible={singnetModal}
        onClose={() => {
          setSingnetModal(false);
        }}
        title={'SingNet email accounts not supported'}
      >
        <Modal.Content style={{ width: '460px' }}>
          <ModalText type="body">
            Use an alternative email or mobile to log in instead.{' '}
            <Text
              type="link"
              inline
              onMouseDown={() =>
                window
                  .open(
                    'https://www.singtel.com/personal/support/singnet#accountupdate',
                    '_blank'
                  )
                  .focus()
              }
            >
              Learn more
            </Text>
          </ModalText>
          <div>
            <ButtonWrapper>
              <Button fullWidth onClick={() => setSingnetModal(false)}>
                OK, got it
              </Button>
            </ButtonWrapper>
          </div>
        </Modal.Content>
      </Modal>
      <Modal
        visible={reloadModal}
        onClose={onRelaodPage}
        title={GENERAL.login.refreshModal.title}
      >
        <Modal.Content style={{ width: '460px' }}>
          <ModalText type="body">{GENERAL.login.refreshModal.body}</ModalText>
          <div>
            <ButtonWrapper>
              <Button fullWidth onClick={onRelaodPage}>
                {GENERAL.login.refreshModal.button}
              </Button>
            </ButtonWrapper>
          </div>
        </Modal.Content>
      </Modal>
      {!showTraditionalCaptcha && (
        <ReCAPTCHA
          ref={captchaRef}
          size="invisible"
          sitekey={SETTINGS.CAPTCHA_SITE_KEY}
        />
      )}
    </>
  );
}
